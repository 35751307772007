<template>
	<div class="moderesources" style="padding-top: 50px;padding-bottom: 50px; background-color: #f8f8f8;">
		<el-row style="width: 1100px;margin: 0 auto;">
			<el-col class="" :span="24" style="margin-bottom: 20px;    font-size: 14px;    color: #999;">
				当前位置：<span>{{mianbaoxie1}}</span>
			</el-col>

			<el-col style="background-color: white;padding: 15px; box-shadow: 0px 0px 6px #c4c4c4;width: 73%;">

				<el-col :span="24">
					<el-col class="" :span="24" style="margin-bottom: 20px;">

						<el-row>
							<el-col :span="14">
								<p style="font-size: 18px;font-weight: bold; color: #4f4f4f;height: 56px;line-height: 56px;">{{parameter.name}}</p>
								<div style="display: flex; font-size: 14px; color:#4f4f4f ;">
									<p>作者: <span>{{parameter.author}}</span></p>
									<p style="margin-left: 40px;">下载量: <span>{{parameter.download_number}}</span></p>
									<p style="margin-left: 40px;">点赞量: <span>{{parameter.fabulous}}</span></p>
									<p style="margin-left: 40px;">浏览量: <span>{{parameter.look_number}}</span></p>
								</div>
							</el-col>
							<el-col :span="4" :push="6" style="margin-top: 37px;">
								<el-radio-group v-model="labelPosition" size="small" style="float: right;">
									<el-radio-button label="2D" v-show="img_show">2D</el-radio-button>
									<el-radio-button label="shipin" v-show="video_show" class="shipin">视频</el-radio-button>
									<el-radio-button label="3D" v-show="show">3D</el-radio-button>
									<el-radio-button label="wendnag" v-show="wendang">文档</el-radio-button>
								</el-radio-group>
							</el-col>
						</el-row>
					</el-col>
					<el-col :span="24" style="width: 100%;" v-show="ispreviewjiaoben">
						<div id="previewjiaoben" style="width: 100%;height: 70vh;">

						</div>
					</el-col>
					<el-col class="" :span="24" style="margin-bottom: 30px;" v-show="!ispreviewjiaoben">
						<el-col style="">
							<div class="block" v-show="img1_show">
								<el-carousel height="500px" style="text-align: center">
									<el-carousel-item v-for="itme in parameter.file_image">
										<img style="height: 100%" oncontextmenu="return false;" :src="common.retun_oss_shuiyin_img(itme)">
									</el-carousel-item>
								</el-carousel>
							</div>
							<video id="needvideo" v-show="video1_show" style="width: 100%;height: 100%;" :poster=common.retun_img(parameter.thumbnail)
							 :src=common.retun_ossimg(parameter.file_mp4) controlslist="nodownload" controls="" preload="auto" oncontextmenu="return false"></video>
							<!-- 定义DOM元素，用于在该DOM元素中显示模型或图纸 -->
							<div id="domId" v-show="show1"></div>

						</el-col>

					</el-col>
					<!-- <el-col class="" :span="24" style="border-top:5px solid #d1e0f9;padding-top: 20px;padding-bottom: 20px;">
						<el-col style="">
							<p style="font-size: 16px;line-height: 40px;">概述：</p>
							<span class="rabbit-font">{{parameter.introduce}}</span>
						</el-col>
					</el-col> -->

				</el-col>
			</el-col>
			<el-col :span="6" style="margin-left: 20px; box-shadow: 0px 0px 6px #c4c4c4; border-radius: 10px; overflow: hidden;">
				<el-col :span="24" style="background: #fff;padding-bottom: 50px;">
					<!-- <el-col class="" v-show="!is_zigm" :span="24" style="text-align: center;line-height: 50px;">
						购买需<span style="color:#ffa800;font-weight: bold;font-size: 20px;">{{parameter.goldcoin}}</span>金币
					</el-col> -->

					<el-col class="" :span="24" style="text-align: center;">
						<el-col class="" :span="10">
							<div @click="Collection(parameter.id)" class="gomai" v-show="!is_zigm" style="cursor: pointer; height: 98px;background-color: #c43e1b; color: white; text-align: left; text-indent: 15px;">
								<p style=" color: white; font-size: 14px; padding-top: 20px;"><span style="font-size: 22px;font-weight: bold;line-height: 40px;">{{parameter.goldcoin}}</span>金币</p>
								<p><span v-show="is_zigm">已</span>购买</p>
							</div>
							<div @click="download()" v-show="is_zigm" style="height: 98px;line-height: 98px; background-color: #c43e1b; color: white; cursor: pointer; text-align: left; text-align: center;">
								<p>下载</p>
							</div>
						</el-col>
						<el-col class="Shoppings" :span="7" style="border: 1px solid #CCCCCC;height: 98px; border-right: none; cursor: pointer;">
							<div @click="Shopping(parameter.id)" style="color:#4f4f4f;">
								<p style="width: 50%;display: block;margin: 0 auto; padding-top: 15px;  margin-bottom: 5px;"><img src="../assets/img/gowuchexiangq.svg"
									 alt="" style="width: 100%;"></p>
								购物车
							</div>
						</el-col>
						<el-col class="Shoppings" :span="7" style="border: 1px solid #CCCCCC;height: 98px;border-right: none; cursor: pointer;">
							<div @click="click_Collection(parameter)" style="color:#4f4f4f;">
								<p style="width: 50%; display: block;margin: 0 auto;padding-top:15px; margin-bottom: 5px;"><img src="../assets/img/shoucangxiangqin.svg"
									 alt="" style="width: 100%;"></p>
								收藏
							</div>
						</el-col>
					</el-col>
					<el-col class="" v-show="is_zigm" :span="24" style="text-align: center;line-height: 50px;">
						您已购买
					</el-col>
					<el-col class="" :span="24" v-show="is_zigm" style="text-align: center;">
						<p v-show="!is_animation">请前去BIMFILM软件使用。</p>
						<!-- <el-button type="primary"  @click="download()">下载</el-button> -->
					</el-col>
					<!-- <el-col class="" :span="24" style="height: 50px;line-height: 50px;margin-top: 20px;background: #efefef;text-align: center;font-size: 14px;color: #999;border-top: 1px dashed #999;border-bottom: 1px dashed #999;">
						<span class="mr-1">下载 {{parameter.download_number}} | </span>
						<span class="mr-1">观看 {{parameter.look_number}} </span>
					</el-col> -->
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px;border-bottom: 1px solid #999;">
						<!-- <h4>{{parameter.name}}</h4> -->
						<!-- <el-col class="" :span="24">作者：{{parameter.author}}</el-col> -->
						<el-col class="" :span="24">ID：{{parameter.id}} </el-col>
						<el-col class="" :span="24">{{parameter.resource_type==2 ? '软件版本' :  '文件格式'}}：{{parameter.zip_type}}</el-col>
						<!-- <el-col class="" :span="24" v-show="parameter.resource_type==2">文件格式：{{parameter.zip_type.indexOf('2.0')>=0 ? '.bfm2' : '.bfm'}}</el-col> -->
						<el-col class="" :span="24">素材大小：{{(parameter.file_size / 1024/1024).toFixed(1)}}MB</el-col>
						<el-col class="" :span="24">更新时间：{{parameter.update_time}}</el-col>
						<el-col style="display: flex;" :span="24" v-if="parameter.relation_resourceid && parameter.resource_type==2">
							关联脚本：
							<p style="" class="jiaoben" @click="gotujiaoben(parameter.relation_resourceid)"></p>
						</el-col>
						<el-col style="display: flex;" :span="24" v-if="parameter.relation_resourceid && parameter.resource_type==3">
							关联动画：
							<p style="" class="donghua" @click="gotujiaoben(parameter.relation_resourceid)"></p>
						</el-col>
					</el-col>
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px;border-bottom: 1px solid #999;">
						<div class="d-flex flex-wrap justify-content-start" style="font-size: 15px;color:#666;">
							<el-col class="" :span="10" v-show="parameter.bind!=0">绑定：<img src="../assets/img/duihao.png" alt=""> </el-col>
							<el-col class="" :span="10" v-show="parameter.map!=0">贴图：<img src="../assets/img/duihao.png" alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.animation!=0">动画：<img src="../assets/img/duihao.png" alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.plugin!=0">插件：<img src="../assets/img/duihao.png" alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.mat!=0">材质：<img src="../assets/img/duihao.png" alt=""></el-col>
							<el-col class="" :span="10" v-show="parameter.dent!=0">塌陷：<img src="../assets/img/duihao.png" alt=""></el-col>
						</div>
					</el-col>
					<el-col class="" :span="24" style="line-height: 40px;text-align: left;padding: 15px;font-size: 14px; border-bottom: 1px solid #999;">
						<el-col class="" :span="24" v-show="parameter.point!=null">点：{{parameter.point}}</el-col>
						<el-col class="" :span="24" v-show="parameter.side!=null">面：{{parameter.side}}</el-col>
					</el-col>
					<el-col style="padding: 15px;">
						<p>简介:</p>
						<p style="line-height: 25px; font-size: 14px;">{{parameter.introduce}}</p>
					</el-col>
				</el-col>
			</el-col>
			<el-col class="" :span="24" style="padding-top: 30px;margin-bottom: 20px;line-height: 40px;background: white; padding: 34px 40px; margin-top: 20px;box-shadow: 0px 0px 6px #c4c4c4;width: 73%;font-size: 14px;">
				<!-- <el-col style="font-size: 20px;" :span="24">注意事项购买前请仔细查看以下说明</el-col> -->
				<el-col style="" :span="24">模型库说明:</el-col>
				<el-col style="" :span="24">1、购买需支付相应金币，购买后可永久使用；</el-col>
				<el-col style="" :span="24">2、模型购买后可在BIMFILM中【我的模型】-【已购买模型】中下载使用；</el-col>
				<el-col style="" :span="24">3、模型库中的模型购买后暂不支持下载源文件，只能在BIMFILM中使用；</el-col>
				<el-col style="" :span="24">动画库说明:</el-col>
				<el-col style="" :span="24">1、动画库中的动画购买后可下载bfm源文件在BIMFILM中使用；</el-col>
				<el-col style="" :span="24">2、bfm格式的是BIMFILM-v1.0,bfm2格式的是BIMFILM-v2.0两者不兼容，购买前仔细查看软件版本信息；</el-col>
				<el-col style="" :span="24">3、模型动画购买后暂不支持退换，除非购买的资源与所展示的不一致。</el-col>
				<el-col style="" :span="24">权益说明：</el-col>
				<el-col style="" :span="24">1、所有资源购买后暂不支持退换，除非购买的资源与所展示的不一致。</el-col>
				<el-col style="" :span="24">2、资源所得收益平台与个人版用户分享5：5分成，平台与企业正式版分享4：6分成（平台4，用户6）；</el-col>
				<el-col style="" :span="24">版权说明：</el-col>
				<el-col style="" :span="24">在本站购买的素材资源只拥有使用权，著作权归作者及BIM秀秀网所有。</el-col>
				<el-col style="" :span="24">未经合法授权，会员不得以任何形式发布、传播、复制、转售该资源。</el-col>

			</el-col>
		</el-row>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<el-select v-model="value" placeholder="请选择收藏的文件夹">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-input style="width:320px; margin-right: 10px; margin-top: 20px;" placeholder="请输入内容" maxlength=8 v-model="add_ProjectList_input"></el-input>
			<el-button @click='addfolder'>添加文件夹</el-button>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="selection_box()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="download_dialogVisible" width="70%">
			<template>
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="file_mp4" label="mp4">
					</el-table-column>
					<el-table-column prop="file_origin" label="源文件">
					</el-table-column>
					<el-table-column prop="file_document" label="文档">
					</el-table-column>
					<el-table-column width="440px">
						<template slot-scope="scope">
							<el-row>
								<el-col>
									<el-button type="primary" size='mini' @click='download_file(scope.row.file_mp4,scope.row)' :disabled='scope.row.file_mp4==null?true:false'>下载mp4</el-button>
									<el-button type="primary" size='mini' @click='download_file(scope.row.file_origin,scope.row)' :disabled='scope.row.file_origin==null?true:false'>下载源文件</el-button>
									<el-button type="primary" size='mini' @click='download_file(scope.row.file_document,scope.row,"doc")' :disabled='scope.row.file_document==null?true:false'>下载文档</el-button>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
				<div style="margin-top: 20px;" v-show="progress>0 && progress<100">
					<p style="margin-bottom:6px">视频下载进度：</p>
					<el-progress :text-inside="true" :stroke-width="26" :percentage="progress" color="#cb5031"></el-progress>
				</div>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button @click="download_dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="download">确 定</el-button>
			</span>
		</el-dialog>
		<!--<login  v-show="showlogin" @closeDialog="closeDialog"></login>-->
	</div>
</template>
<script>
	import {
		requestDetails,
		requestAddpush,
		requestaccount_collect_add,
		requestResource_purchase,
		requestdirectory_add,
		requestAccount_cart,
		resource_download,
		requestAccount_crumbs,
		preview_script,
		requestdirectory_list,

	} from "../api/request";
	import BimFace from '../assets/js/BimfaceSDKLoader@latest-release.js'
	import aliyun from '../assets/js/aaa.js'
	export default {
		name: "BimFace",
		components: {
			BimFace,
		},
		data() {
			return {
				radio: '1',
				progress:0,
				parameter: {},
				labelPosition: '2D',
				show: true,
				video_show: true,
				img_show: false,
				show1: false,
				video1_show: false,
				img1_show: true,
				viewer3D: '',
				app: '',
				viewAdded: false,
				isMapLoaded: false,
				loaderConfig: null,
				viewer: null,
				is_click: 0,
				BimfaceLoaderConfig: new BimfaceSDKLoaderConfig(),
				//select选择框
				options: [],
				//选择框绑定
				value: '',
				//资源数据
				resource_data: '',
				is_zigm: false,
				add_ProjectList_input: '',
				Child_list: '',
				current_select_level1_item: null,
				mianbaoxie1: "",
				is_animation: null,
				download_dialogVisible: false,
				tableData: [],
				rotate: true,
				imgurl: require('../assets/img/rotate.png'),
				filename: null,
				ispreviewjiaoben: false,
				dialogVisible: false,
				wendang: false
			};
		},
		methods: {
			//3D预览
			onSDKLoadSucceeded(viewMetaData) {
				var modelstate = "";
				if (viewMetaData.viewType == "drawingView") {
					var view = document.getElementById('domId');
					var WebAppConfig = new Glodon.Bimface.Application.WebApplicationDrawingConfig();
					WebAppConfig.domElement = view;
					var app = new Glodon.Bimface.Application.WebApplicationDrawing(WebAppConfig);
					viewer = app.getViewer();
					viewer.addModel(viewMetaData);
					modelstate = "2D";

				} else if (viewMetaData.viewType == "3DView") {
					var view = document.getElementById('domId')
					var config = new Glodon.Bimface.Application.WebApplication3DConfig();
					config.domElement = view;
					var eventManager = Glodon.Bimface.Application.WebApplication3DEvent;
					this.app = new Glodon.Bimface.Application.WebApplication3D(config);
					this.viewer = this.app.getViewer();
					this.viewer.addModel(viewMetaData);
					modelstate = "3D";

				} else if (viewMetaData.viewType == "rfaView") {
					var view = document.getElementById('domId')
					//var config = new Glodon.Bimface.Application.WebApplicationRfaConfig();
					var config = new Glodon.Bimface.Application.WebApplication3DConfig();
					config.domElement = view;
					var eventManager = Glodon.Bimface.Application.WebApplicationRfaEvent;
					app = new Glodon.Bimface.Application.WebApplicationRfa(config);
					this.viewer = this.app.getViewer();
					viewer.addModel(viewMetaData);
					modelstate = "3D";
				}
				let _this = this;
				this.viewer.addEventListener(Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded, function() {

					if (modelstate == "3D") {
						_this.viewer.startAutoRotate(-2);
						_this.viewer.setExposureShift(-0.1);
						_this.viewer.setBorderLineEnabled(false);

						btnRotate("自动旋转", 1);

						function btnRotate(title, inde) {
							var toolbar = _this.app.getToolbar('MainToolbar');
							var btnConfig = new Glodon.Bimface.UI.Button.ButtonConfig();
							btnConfig.title = title;

							var btn = new Glodon.Bimface.UI.Button.Button(btnConfig);
							console.log(btn)
							//btn.setHtml("<i class='fa fa-rotate-right' onclick='onrotate();' style='font-size: 24px;'></i>");
							btn.setHtml("<img src='" + _this.imgurl + "'  style='width: 24px;' alt=''>"); //<i class='fa fa-rotate-right' onclick='onrotate();' style='font-size: 24px;'></i>
							btn.addClassName("rotate");
							btn.element.onclick = () => {
								_this.onrotate()
							}
							toolbar.insertControl(inde, btn);
						}
					}

				});
			},

			//3D预览报错
			onSDKLoadFailed(error) {
				console.log("Failed to load SDK!");
			},
			//下载
			download() {

				if (this.parameter.resource_type == 1) {
					this.$message({
						message: '请去BIMFILM中使用',
						type: 'warning',
						offset: 100
					});
					return
				}



				let aaa;
				if (this.parameter.resource_type == 3) {
					aaa = this.tableData[0].file_document.split('.')[1];
				} else if (this.parameter.resource_type == 2) {
					aaa = this.tableData[0].file_mp4.split('.')[1];
					aaa = this.tableData[0].file_origin.split('.')[1];


				} else if (this.parameter.resource_type == 1) {
					aaa = this.tableData[0].file_origin.split('.')[1];
				}
				console.log(aaa)
				this.filename = aaa;
				this.download_dialogVisible = true;
			},
			//关联脚本详情
			gotujiaoben(id) {
				window.open("/#/ModeResources?id=" + id)
			},
			click_Collection(_this) {
				console.log("log11")
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				}
				this.dialogVisible = true;
				this.resource_data = _this;
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					this.current_select_level1_item = res.ret[1];
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.Child_list = res.ret;
						this.options = [];
						//二级列表赋值
						for (let i = 0; i < res.ret.length; i++) {
							this.options.push({
								value: res.ret[i].node_id,
								label: res.ret[i].name
							})
						}
					});
				});


			},
			addfolder() {
				if (this.add_ProjectList_input == '') {
					this.$message({
						message: '请填写项目名称',
						type: 'warning',
						offset: 200
					});
					return
				};
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.Child_list.length; h++) {

					if (this.Child_list[h].sequence > max_sequence) {
						max_sequence = this.Child_list[h].sequence;
					}
					if (this.Child_list[h].name == this.add_ProjectList_input) {

						this.$message({
							message: '不可添加重复文件夹名称',
							type: 'warning',
							offset: 200
						});
						return
					}
				};
				let sequence = max_sequence + 1;
				let node_idstr = this.common.retun_getnodeid(
					this.current_select_level1_item.node_id,
					2,
					sequence
				);
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: this.current_select_level1_item.id,
					level: 2,
					sequence: sequence,
					node_id: node_idstr,
					parent_node_id: this.current_select_level1_item.node_id
				};
				//添加请求
				let _this = this;
				requestdirectory_add(addData).then((res) => {
					if (res.code == 0) {
						_this.$message({
							message: '添加成功',
							type: 'success',
							offset: 200
						});
						_this.add_ProjectList_input = ''
					}
					this.dialogVisible_add = false;
					let data = {
						parent_id: 0
					};
					requestdirectory_list(data).then(res => {
						// 一级列表赋值
						let datas = {
							parent_id: res.ret[1].id
						};
						requestdirectory_list(datas).then(res => {
							_this.Child_list = res.ret;
							_this.options = [];
							//二级列表赋值
							for (let i = 0; i < res.ret.length; i++) {
								_this.options.push({
									value: res.ret[i].node_id,
									label: res.ret[i].name
								})
							}
						});
					});
				});
			},
			selection_box() {
				if (this.value == '') {
					this.$message({
						type: 'info',
						message: `请选择文件夹`,
						offset: 100
					});
					return
				}
				let data = {
					"resource_id": this.resource_data.id,
					"node_id": this.value
				}
				requestaccount_collect_add(data).then(res => {
					this.dialogVisible = false;

				})

			},
			downVideo(url, name) {
				var xhr = new XMLHttpRequest();
				xhr.open('GET', url, true);
				xhr.responseType = 'arraybuffer'; // 返回类型blob
				xhr.onload = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						let blob = this.response;
						// 转换一个blob链接
						let u = window.URL.createObjectURL(new Blob([blob], {
							type: 'video/mp4'
						}))
						let a = document.createElement('a');
						a.download = name;
						a.href = u;
						a.style.display = 'none'
						document.body.appendChild(a)
						a.click();
						a.remove();
					}
				};
				xhr.onprogress = (event) => {
					if (event.lengthComputable) {
					const percentComplete = ((event.loaded / event.total) * 100).toFixed(
						2
					);
					this.progress = parseFloat(percentComplete);
					}
				};
				xhr.send()
			},

			download_file(file, file_list,type='') {
				let _this = this;
				let aaa = file.split('.')[1];
				let bbb = '.' + aaa;
				let data = {
					resource_id: file_list.id,
					md5: file.split('.')[0],
				};
				console.log('4545')
				const OSS = require('ali-oss');

				const client = new OSS({
					// yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
					region: 'oss-cn-beijing',
					// 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
					accessKeyId: 'LTAI5tMWyjDaM5RSNBCA2t1E  ',
					accessKeySecret: 'GgoJDMISPXontVZIcDERBrOlFSDHxj',
					// 填写Bucket名称。
					bucket: 'resourcelib'
				});

				
			
				
				const filename = file_list.name // 自定义下载后的文件名称。
				const response = {
					'content-disposition': `attachment; response-content-disposition=attachment; filename=${encodeURIComponent(filename)}`
				}
				let url="";
				this.$message({
					message: '下载中，请稍等',
					type: 'success',
					offset: 100
				});
				if(type=='doc'){
					 url = client.signatureUrl('resource/resource_jiaoben/'+file,response);
				}else{
					 url = client.signatureUrl('upload/'+file,response);
				}
				console.log(url);
				console.log(type);
			//	return false;
				// _this.common.modalnormaldownload(file_list.name, url)
				console.log(url)
				if (aaa == 'mp4') {
				
				
					this.downVideo(url, file_list.name);
					// _this.common.downloadfile(res.ret[0], file_list.name, bbb);
				} else {
					_this.common.modalnormaldownload(file_list.name, url)
				}
				// resource_download(data).then(res => {
				// 	if (res.code == 0) {
				// 		this.$message({
				// 			message: '下载中，请稍等',
				// 			type: 'success',
				// 			offset: 100
				// 		});
				



				// if (aaa == 'mp4') {
				// 	_this.common.downloadfile(res.ret[0], file_list.name, bbb);
				// }
				// 	}
				// })
			},
			//获取当前资源详情并赋值
			Details: function() {
				let id = this.common.GetRequest().id,
					_this = this;
				let data = {
					id: id
				};
				let data1 = {
					resource_id: id
				};
				requestDetails(data).then(function(e) {

					_this.ispreviewjiaoben = false;
					if (e.ret.resource_type == 3) {
						_this.huifucolo();
						_this.$parent.$refs.headerTop.$refs.navlist[2].style.color = '#cb5031'
						_this.labelPosition = 'wendnag'
						_this.video_show = false;
						_this.wendang = true;
						let datas = {
							object: e.ret.file_document
						}
						console.log(localStorage.getItem("token"))
						preview_script(datas).then(res => {
							// console.log(res)
							_this.ispreviewjiaoben = true;
							preview(res.ret)

							function preview(previewInfo) {
								let demo = aliyun.config({
									mount: document.querySelector('#previewjiaoben'),
									url: previewInfo.PreviewURL, //设置文档预览URL地址。,
									wordOptions: {
										isBestScale: true,
									},
									pdfOptions: {
										isInSafeMode: true,
									},


								})

								//设置AccessToken。
								demo.setToken({
									token: previewInfo.AccessToken
								})
							}
							console.log(document.querySelector('#previewjiaoben'))
							document.querySelector('#office-iframe').style.width = '100%'
							document.querySelector('#office-iframe').style.height = '70vh'
						})
					}


					if (e.ret.pack_type == 6) {
						_this.is_animation = true;
						_this.tableData.push(e.ret)
						if (_this.tableData[0].file_origin.split('.')[1] == undefined) {
							_this.tableData[0].file_origin = _this.tableData[0].file_vbim;
						}
					} else {
						_this.is_animation = false;
					}

					if (e.ret.resource_type == 3) {
						_this.is_animation = true;
						_this.tableData.push(e.ret)
					}

					if (e.ret.update_time == null) {
						e.ret.update_time = e.ret.create_time
					}
					if (e.ret.file_image != undefined && e.ret.file_image != null && e.ret.file_image != "") {
						e.ret.file_image = e.ret.file_image.split(",")
					} else {
						e.ret.file_image = e.ret.thumbnail.split(",")
					}
					if (e.ret.resource_type == 2) {

						_this.huifucolo();
						_this.$parent.$refs.headerTop.$refs.navlist[1].style.color = '#cb5031'
						_this.labelPosition = 'shipin';
						_this.show1 = false;
						_this.video1_show = true;
						_this.img1_show = false;
						if (e.ret.zip_type != null) {
							if (e.ret.ver_name == 'v1.0' || e.ret.zip_type == '.bfm') {
								e.ret.zip_type = 'BIMFILM-V1.0'
							} else if (e.ret.ver_name == 'v2.0' || e.ret.zip_type == '.bfm2') {
								e.ret.zip_type = 'BIMFILM-V2.0'
							} else if (e.ret.ver_name == 'v3.0' || e.ret.zip_type == '.bfm3') {
								e.ret.zip_type = 'BIMFILM-V3.0'
							}
						} else {
							e.ret.zip_type = e.ret.soft_name + '-' + e.ret.ver_name;
						}
					}
					// if(e.ret.resource_type==1){
					// 	_this.labelPosition='shipin';
					// 	_this.show1 = false;
					// 	_this.video1_show = true;
					// 	_this.img1_show = false;
					// 	 e.ret.zip_type= e.ret.soft_name+'-'+e.ret.ver_name;
					// }
					_this.parameter = e.ret;
					if (e.ret.offlinepackurl == null) {
						_this.show = false
					} else {
						_this.show1 = false
					}
					if (e.ret.resource_type == 1) {
						_this.img_show = true
						_this.video_show = false
						_this.video1_show = false
						_this.show = true;

						_this.huifucolo();
						_this.$parent.$refs.headerTop.$refs.navlist[0].style.color = '#cb5031';
					}

					requestResource_purchase(data1).then(function(e) {
						if (e.code == 0) {
							_this.is_zigm = true
						} else {
							_this.is_zigm = false
						}
					});
				});

			},
			huifucolo() {
				let _this = this;
				_this.$parent.$refs.headerTop.$refs.gowuche.$el.style.color = '#000'
				_this.$parent.$refs.headerTop.$refs.shangchuan.$el.style.color = '#000'
				_this.$parent.$refs.headerTop.$refs.yulan.$el.style.color = '#000'
				_this.$parent.$refs.headerTop.$refs.kefu.style.color = '#000'
				_this.$parent.$refs.headerTop.$refs.shoye.style.color = '#000'
				_this.$parent.$refs.headerTop.$refs.xiuxiu.style.color = '#000'
				for (let h = 0; h < _this.$parent.$refs.headerTop.$refs.navlist.length; h++) {
					_this.$parent.$refs.headerTop.$refs.navlist[h].style.color = '#000'
				}
			},
			onrotate() {
				this.rotate = !this.rotate;
				if (this.rotate) {
					this.viewer.startAutoRotate(-2);
				} else {
					this.viewer.startAutoRotate(0);
				}
			},
			//加购
			Shopping(e) {
				let _this = this;
				requestAddpush(e).then(function(res) {
					if (res.code == 0) {
						_this.$message({
							message: '加入购物车成功',
							type: 'success',
							offset: 100
						});
					} else if (res.code == 5012) {
						_this.$confirm('BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							_this.$router.push("/VipPrivilge")
						}).catch(() => {
							_this.$message({
								type: 'info',
								message: '已取消前往购买',
								offset: 100
							});
						});
					}
				})
			},
			//购买
			Collection(e) {


				if (this.is_zigm) {
					this.$message({
						message: '此资源您已购买',
						type: 'success',
						offset: 100
					});
					return
				}


				console.log("log11")
				if (this.Account.GetSession() == null) {
					this.$router.push('SignIn')
					return
				};
				let obj = {
					resource_id: this.parameter.id,
					goldcoin: this.parameter.goldcoin,
					owner_id: this.parameter.account_id,
				}
				let data_this = this,
					ayy_ay = [];
				ayy_ay.push(obj);
				this.$confirm('因BIMFLM各版本之间数据互不兼容，请在购买前仔细查看模型动画的软件版本号,并确保您安装有此版本软件，否则下载的资源将无法使用，是否购买此资源?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					requestAccount_cart(ayy_ay).then(res => {
						if (res.code == 0) {
							data_this.$message({
								message: '已成功购买！',
								type: 'success',
								offset: 100
							});
							data_this.common.coin_calculation(1, data_this.parameter.goldcoin)
							setTimeout(function() {
								data_this.$router.go(0)
							}, 1000)
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消购买'
					});
				});

			},
			//面包屑
			mianbaoxie: function() {
				let info = this.$router.currentRoute.query.id;
				requestAccount_crumbs(info).then(res => {
					console.log(res)
					let mianbao = "";
					for (var i = 0; i < res.ret.length; i++) {
						if (res.ret.length != i + 1) {
							mianbao += res.ret[i].name + ">"
						} else {
							mianbao += res.ret[i].name
						}
					}
					this.mianbaoxie1 = mianbao


				})

			}
		},
		watch: {
			labelPosition: function(newVal, oldVal) {
				let _this = this;
				//单选监听实时获取
				if (newVal == "2D") {
					this.show1 = false;
					this.video1_show = false;
					this.img1_show = true
				} else if (newVal == "3D") {
					this.show1 = true;
					this.video1_show = false;
					this.img1_show = false;
					if (this.is_click == 0) {
						_this.BimfaceLoaderConfig.dataEnvType = BimfaceEnvOption.Local;
						_this.BimfaceLoaderConfig.sdkPath = _this.parameter.jssdk + '/jssdk';
						_this.BimfaceLoaderConfig.path = _this.parameter.offlinepackurl + '/viewToken.json';
						BimfaceSDKLoader.load(_this.BimfaceLoaderConfig, _this.onSDKLoadSucceeded, _this.onSDKLoadFailed);
					}
					this.is_click = 1
				} else if (newVal == 'shipin') {
					this.show1 = false;
					this.video1_show = true;
					this.img1_show = false;
				} else if (newVal == 'wendang') {
					this.show1 = false;
					this.show = false;
					this.video1_show = false;
					this.video_show = false;
					this.img1_show = false;
					this.img_show = false;
				}
			},


		},
		mounted() {
			if (this.Account.GetSession() == null) {
				this.$router.push('SignIn')
				return
			};
			this.mianbaoxie();
			this.Details();
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = 'https://res.bimshows.cn/bimface/jssdk/BimfaceSDKLoader@latest-release.js';
			document.body.appendChild(s);
			document.body.scrollTop = document.documentElement.scrollTop = 0;

		},
	};
</script>
<style scoped>
	@import "../style/common.css";

	.shaixuan {
		background: #fff;
	}

	.shaixuan>div {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px dashed #ccc;
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #e6e6e6;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #e6e6e6;
	}

	.buttons {
		font-size: 0;
	}

	.button {
		margin: 5px 0 5px 5px;
		width: 100px;
		height: 30px;
		border-radius: 3px;
		border: none;
		background: #32D3A6;
		color: #FFFFFF;
	}

	.main {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}

	#domId {
		flex: 1;
		height: 500px;
	}

	>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		background-color: #c43d1d;
	}

	.gomai:hover {
		background-color: rgba(255, 146, 98, 0.9) !important;
	}

	.Shoppings:hover {
		background-color: #dbdbdb;
	}

	.donghua {
		width: 30px;
		height: 30px;
		cursor: pointer;
		background: url(../assets/img/donghuaicon.svg) no-repeat center center;
		background-size: 100% 100%;
		;
	}

	.donghua:hover {
		background: url(../assets/img/donghuaiconhover.svg) no-repeat center center;
		background-size: 100% 100%;
		;
	}

	.jiaoben {
		width: 30px;
		height: 30px;
		cursor: pointer;
		background: url(../assets/img/wendangicon.svg) no-repeat center center;
		background-size: 100% 100%;
		;
	}

	.jiaoben:hover {
		background: url(../assets/img/wendangiconhover.svg) no-repeat center center;
		background-size: 100% 100%;
		;
	}

	>>>.ship>span {
		border-radius: 0 4px 0 4px !important;
	}

	>>>.el-button--primary.is-active,
	.el-button--primary:active {
		background: rgb(203, 80, 49);
		border-color: rgb(203, 80, 49);

	}

	>>>.el-button--primary {
		background: rgb(203, 80, 49);
		border-color: rgb(203, 80, 49);
	}

	>>>.el-button--primary:focus,
	.el-button--primary:hover {
		background: rgb(203, 80, 49);
		border-color: rgb(203, 80, 49);
	}
</style>
