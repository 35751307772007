<template>
	<div class="head" ref='head' style="position: fixed;">
		<!-- 头部 -->
		<el-row type="flex" class="row-lg" justify="center" style="z-index: 9; background:hsla(0,0%,100%,.1); backdrop-filter:blur(8px);">
			<el-row style="height: 64px; width: 100%;">
				<!-- logo -->
				<el-col style="margin-left: 15px; width: 10%;">
					<img src="../assets/img/newlogo.png" @click="toindex()" ref='img' style="width: 100%;  margin-top: 12px; cursor: pointer;"
					 alt />
				</el-col>
				<el-col style=" width: 30%; display:flex;" ref='navbar'>
					<div class='gowuc' ref='ShoppingCarts' style=' text-align: center; width: 18%;'>
						<span @click="toindex()" style=' cursor: pointer;font-size: 14px; color: #cb5031 ;' ref='shoye'>首页</span>
					</div>
					<div @mouseout="mouseout_catalog" style="width: 80%; display: flex;">
						<div class='gowuc' ref='ShoppingCarts' v-for="(itme,index) in navBar" style=' text-align: center; width: 25%;'>
							<span style="font-size: 16px; cursor: pointer; display: flex;font-size: 14px; " @mouseover="mousemove_catalog(itme,2)"
							 ref='navlist' @click="modebase(itme.id,itme.node_id,itme.sequence,index,itme.level)">
								<span style="width: 100%; display: block;">{{itme.name}}<i class="el-icon-caret-bottom"></i> </span>
							</span>
						</div>
					</div>
					<div class='gowuc' ref='ShoppingCarts' style=' text-align: center;width: 18%;'>
						<span @click="myproject()" style=' cursor: pointer; font-size: 14px; ' ref='xiuxiu'>我的秀秀</span>
					</div>
					<!-- <div class='gowuc' style=' text-align: center;width: 20%;'>
						<span @click="OfficialActivities()" style=' cursor: pointer; font-size: 14px; ' >收费资讯</span>
					</div> -->
					<!-- <div class='gowuc' style=' text-align: center;width: 20%;'>
						<span @click="OfficialActivities()" style=' cursor: pointer; font-size: 14px; ' >官方活动</span>
					</div> -->
				</el-col>
				<!-- 搜索框 -->
				<el-col style="width: 25%;margin-right: 3%; margin-left: 3%;">
					<ComTopSearch ref="ComTopSearch"></ComTopSearch>
				</el-col>
				<el-col style="width: 19%; display: flex;justify-content:space-between; ">
					<el-col :span='4' class='gowuc' ref='gowuche' style=''>
						<span @click="href_shop()" style=' cursor: pointer;font-size: 14px;'>购物车</span>
					</el-col>
					<el-col :span='4' class='gowuc' ref='shangchuan'>
						<span @click="upload()" style=' cursor: pointer;font-size: 14px;'>上传</span>
					</el-col>
					<el-col :span='4' class='gowuc' >
						<span @click="kefu()"ref='kefu' style=' cursor: pointer;font-size: 14px;'>客服</span>
						<section class="QQ" ref='leftdata'>
							<el-row style='background:#f7f7f7;'>
								<el-row>
									<el-button size="medium" round style="margin: 10px auto 5px; display: block;"> <a target="_blank" href="https://jq.qq.com/?_wv=1027&k=cCEmTJ93"><img
											 border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="BIM-FILM技术交流④群" title="BIM-FILM技术交流④群"></a></el-button>
								</el-row>
							</el-row>
							<el-row style='background:white;border-bottom: 1px  silver solid;'>
								<el-row>
									<p style="line-height:25px; font-size: 14px; margin-top:10px;">联系站长</p>
									<span style="line-height:20px; font-size: 14px;  display: block;">bimfilm@bimfilm.cn</span>
								</el-row>
								<el-row>
									<p style="line-height:25px; font-size: 14px; margin-top:10px;">联系电话</p>
									<span style="line-height:20px; font-size: 14px;  display: block; margin-bottom: 10px;">400-068-1805
										转2</span>
								</el-row>
							</el-row>
							<el-row style='background:white; border-bottom: 1px silver solid;'>
								<el-row>
									<p style="font-size: 16px; text-align: center; line-height: 30px;margin-top: 20px;">(您的用户id: <span>{{account_id}}</span>
										)</p>
									<p style="font-size: 14px; text-align: center;line-height: 25px;">周一至周五:9:00-21:00</p>
									<p style="font-size: 14px; text-align: center; margin-bottom: 20px; line-height: 25px;">周末及节日:9:00-18:00</p>
								</el-row>
							</el-row>
							<el-row style='background:white;'>
								<el-row>
									<img src="../assets/img/qrcode.jpg" alt="" width="100%" height="100%">
									<p style="font-size: 14px; text-align: center;line-height: 32px;margin-top:-42px">官方公众号</p>
								</el-row>
							</el-row>
						</section>
					</el-col>
					<el-col :span='5' class='gowuc' ref='yulan'>
						<span style=' cursor: pointer;font-size: 14px;' @click="quanjingtu">360°全景</span>
					</el-col>
					<el-col :span='4' class='gowuc' ref='ShoppingCarts' style=''>
						<span style=' cursor: pointer;font-size: 14px;' @click="huifucolo()"> <a href='https://account.bimfilm.cn/help?leixing=bim'
							 target="_blank" style="color: #000000;">帮助</a> </span>
					</el-col>
				</el-col>
				<!-- 登录/注册  8 -->
				<el-col class="login" ref='logins' v-if="isLogin == 0" style="float: right;background-color: #c43e1b;color: white;width: 5%;  text-align: center; cursor: pointer;">
					<div @click="loginevent">
						<el-link type="primary" style='color: white; text-decoration:none'>登录</el-link>
						/
						
						<el-link style='color: white;'>注册</el-link>
					</div>
					<!--<a :href="open">注册</a> -->
				</el-col>
				<el-col class="header-right" v-else-if="isLogin == 1" style="float: right;color: white;width: 5%; text-align: center;">
					<div style="display: flex;padding-top: 10px;" @mouseover="seover" @mouseout="show3 = false">
						<div style="width:28px ; height:20px; overflow: hidden;" class="imgdiv" ref='headportrait'>
							<!-- <img style="z-index:1000;transition: 1s; width: 100%;  " :src="common.retun_touxiangimg(session.headpath)" alt=""
							 @click="amend"  /> -->
						</div>
						<p style=" font-size: 12px; margin-left: 10px; color:#666666; cursor: pointer;transition: 1s;" @click="amend" ref='name'><span
							 style='color: white; position: relative;'>
								<!-- <img :src="common.retun_touxiangimg(session.headpath)" alt /> -->
								<img :src="common.retun_touxiangimg(session.headpath)" alt="" style="width:35px;border-radius: 50%; overflow: hidden;height: 35px;">
								<img src="../assets/img/vipicon.png" style="display: inline-block;margin-left: 20px;transform: translateY(6px); position: absolute; left:-90%; bottom:-10px;width:60px;"
								 v-show="vip_icon_show">
							</span>
						</p>
					</div>
					<el-collapse-transition>
						<div class="img-message" ref='message' v-show="show3" @mouseout="show3 = true" v-on:mouseleave="mouseleave1()">
							<div class="modifieddata">
								<div class="modifieddata-one">
									<img :src="common.retun_touxiangimg(session.headpath)" alt />
									<!-- <img src="../assets/img/VIPhead.png" alt=""> -->
								</div>
								<div class="header_title">
									<p><span>{{ session.nickname }}</span> {{session.type_role}}</p>
									<p>手机号:{{ session.phone }}</p>
									<p>邮&nbsp;&nbsp;&nbsp;箱:{{ session.email }}</p>
								</div>
								<span style="font-size: 12px;">

									<b @click="amend" style="font-size: 12px;">修改资料》</b>
								</span>
							</div>
							<div class="dredge-vip">
								<!-- <div class="dredge-vip1" @click="liberal">
									<span class="dredge-vip-cc">
										企业特权：{{vipsession.vip_type == 0? "": vipsession.vip_type == 1? "vip": "内部账号"}}
									</span>
									<span>
										<b>查看权限>></b>
									</span>
								</div> -->
								<div style="border-bottom: 1px solid #CCCCCC;" @click="myqb">
									<div class="dredge-vip2">
										<span class="dredge-vip-cc" style="font-size: 12px;">
											我的钱包:
											<!-- <a>{{ vipsession.goldcoin }}金币</a> -->
										</span>
										<span>
											<b @click="myqb" style="font-size: 12px; color: #c95031;">点击查看>></b>
										</span>
									</div>
									<div class="GoldCoin">
										<p>可用金币<span>{{ vipsession.goldcoin }}</span></p>
										<p>可提现金币<span>{{vipsession.resource_goldcoin}}</span></p>
									</div>
								</div>
							</div>

							<div class="internal-storage">
								<div class="internal-storage2" @click="weidu">
									<span class="dredge-vip-cc" style="font-size: 12px;">
										我的消息:
									</span>
									<span>
										<b @click="weidu" style="color: #c95031;cursor: pointer; font-size: 12px;">
											您有{{
                      unread_message
                      }}条未读消息》</b>
									</span>
								</div>
								<!--<div class="internal-storage1">-->
								<!--<span class="dredge-vip-cc">-->
								<!--内存空间:-->
								<!--<a>-->
								<!--{{vipsession.total_storage-vipsession.use_storage}}MB可用-->
								<!--</a>-->
								<!--</span>-->
								<!--<span>-->
								<!--<b @click="dilatation">扩容>></b>-->
								<!--</span>-->
								<!--</div>-->
								<!--<div class="internal-storage2">我的通知</div>-->
							</div>

							<div class="img-message-bottom">
								<span @click="mima()" style="font-size: 12px;">密码修改》</span>
								<span @click="signout()" style="font-size: 12px;">退出登录》</span>
								<!-- <button>密码修改</button>
                <button>退出登录</button>-->
							</div>
						</div>
					</el-collapse-transition>
				</el-col>
			</el-row>
			<el-collapse-transition>
				<div class="PopUp" v-show="nav_top" @mouseleave="mouseout_catalog">
					<div style=" background-color: #f3f3f3;">
						<ul style="width:200px; margin: 10px; padding-left: 290px;" ref='list_ul'>
							<li v-for="(item2,idnex2) in catalog_list_level2" class="one_list_li" id='one_list_li' ref='one_list_li' style="line-height:30px; cursor: pointer;"
							 @mouseover="mousemove_catalog(item2,3,idnex2)">
								<p style="font-size: 12px;" @click="library(item2)">{{item2.name}}</p>
								<!-- <ul ref='tow_list_ul' style="display: none; @click="library(item2)"@click="modebase2(item2.id,item2.node_id,item2.sequence,idnex2,item2.level)"">
									<li v-for="(item3,idnex3) in catalog_list_level3"
									 ref='tow_list_li' 
									 style="text-indent: 15px;line-height: 26px;height: 26px; cursor: pointer; display: flex;" 
									 class="tow_list_li"
									 @mouseover="mousemove_catalog(item3,4,idnex3)" >
										<p style="width: 200px; display: block;font-size: 12px;" class="text_over">{{item3.name}}</p>
										
									</li>
								</ul> -->
							</li>
						</ul>
					</div>
					<div style=" display: flex; width: 100%; padding-top: 10px; padding-left: 20px;">
						<ul style="width: 100%;">
							<li v-for="(item4,idnex4) in catalog_list_level3" class="san_list_li" ref='san_list_li'>
								<p style="width:140px;font-size: 12px; text-align: left; display: flex;"><span class="text_over" style="width: 130px; display: block;">
										{{item4.name}}</span><span style="float: right; margin-left: 15px;">|</span></p>
								<ul style="display: flex; width: 60%;flex-wrap: wrap;align-items:stretch">
									<li v-for="(item5,idnex5) in item4.children" class="hovertext si_list_li">
										<!-- <el-tooltip class="item" effect="dark" :content="item5.name"  placement="bottom"> -->
										<p style="font-size: 12px;text-align: left; margin-left: 10px; margin-right: 10px;" @click="click_element(item5)">
											{{item5.name}}
										</p>
										<!-- </el-tooltip> -->
									</li>
								</ul>
							</li>
						</ul>

					</div>
				</div>
			</el-collapse-transition>
		</el-row>
		<!-- <login ref="login" @closeDialog="closeDialog"></login> -->
		<div>
			<el-dialog title="请留下您的宝贵建议" :visible.sync="dialogVisiblse" :modal="false" width="30%">
				<p>聆听用户心声，提供更好服务，我们非常重视您的反馈</p>
				<el-form ref="form" :model="form">
					<el-form-item>
						<p>反馈内容,方便官方改进</p>
						<el-input type="textarea" :rows="5" v-model="form.desc" placeholder="请输入您在使用BIM秀秀网过程中遇到的问题，或宝贵建议，感谢您对BIM秀秀的支持！（优质的提问或建议，通常在10-400字之间哦）"></el-input>
					</el-form-item>
					<el-form-item>
						<p>请留下您的联系方式</p>
						<el-input v-model="form.name" placeholder="请留下您手机号、QQ或邮箱，以便我们及时回复您"></el-input>
					</el-form-item>
				</el-form>

				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisiblse = false">取 消</el-button>
					<el-button type="primary" @click="feedback_determine">确 定</el-button>
				</span>
			</el-dialog>
		</div>

	</div>
</template>
<script>
	import {
		requestSignout,
		requestStorage,
		requestCatalog,
		catalog_list
	} from "../api/request";
	// import login from "@/views/Login.vue";
	import ComTopSearch from "./ComTopSearch";
	export default {
		data() {
			return {
				show3: false,
				index: undefined,
				select: "2",
				inputval: "",
				showlogin: false,
				isLogin: "0", //0未登录 1已登录
				input: "",
				session: "",
				vipsession: "",
				// open:require('../../public/register/register.html')
				top: null,
				vip_icon_show: false,
				navBar: null,
				dialogVisiblse: false,
				form: {
					desc: null,
					name: null
				},
				catalog_list: [],
				catalog_list_level2: [],
				catalog_list_level3: [],
				catalog_list_level4: [],
				one_list_li_index: 0,
				nav_top: false,
				unread_message: null,
				account_id:null,
				resource_type:null
			};
		},
		components: {
			// login,
			ComTopSearch,
		},
		// created(){
		// 	console.log("头部调用执行。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
		// 	this.isLogin1();
		// },
		methods: {
			library(v){
				console.log(v)
				this.$router.push({
					path: '/ModeBase',
					query: {
						identification:true,
						id: v.id,
						level: v.level,
						nodeid: v.node_id,
						sequence: v.sequence,
						parentnodeid:v.parent_node_id,
						level_0:v.id,
						resource_type:this.resource_type,
						istop: true
					}
				})
			},
			click_element(item) {
				console.log(item)
				// itme.id,itme.node_id,itme.sequence
				
				
				
				
				this.$router.push({
					path: '/ModeBase',
					query: {
						level_0: item.id,
						parentnodeid: item.node_id,
						sequence: item.sequence,
						istop: true,
						resource_type:this.resource_type
					}
				})
			},
			OfficialActivities(){
				
				this.$router.push('OfficialActivities_details')
				
				
				
				
				
			},
			kefu(){
				this.huifucolo();
				this.$refs.kefu.style.color = '#cb5031'
				this.getaccount_id();
				if(this.$refs.leftdata.style.display=='block'){
					this.$refs.leftdata.style.display='none'
				}else{
					this.$refs.leftdata.style.display='block';
				}
			},
			getaccount_id:function (){
				if(this.Account.GetStorage()==null){
					return
				}else{
					this.account_id=this.Account.GetStorage().account_id;
				}
			},
			quanjingtu() {
				
				this.huifucolo();
				this.$refs.yulan.$el.style.color = '#cb5031'
				this.$router.push('PanoramaViewer')
			},

			tihuan() {
				for (let g = 0; g < this.catalog_list.length; g++) {
					if (this.catalog_list[g].id == 14126) {
						this.catalog_list[g].id = 10005;
					}
				}
			},


			mousemove_catalog(item, level, index,sequence) {
				if (item.level == 1 && item.sequence == 1) {
					this.resource_type = 1
				} else if (item.level == 1 && item.sequence == 2) {
					this.resource_type = 2
				} else if (item.level == 1 && item.sequence == 3) {
					this.resource_type = 3
				}
				// console.log(this.resource_type)
				console.log(this.MyShow)
				if (this.MyShow.GetHomeCatalog() == null) {
					catalog_list().then(res => {
						console.log(res)
						let data = JSON.parse(res)
						this.MyShow.SetHomeCatalog(data);
						this.catalog_list = res;
						this.tihuan()
					})
				} else {
					this.catalog_list = this.MyShow.GetHomeCatalog();
					this.tihuan()
				}

				// this.catalog_list_level2=[];
				// this.catalog_list_level3=[];
				// this.catalog_list_level4=[];
				// this.catalog_list_level5=[];
				// this.$refs.list_ul.style.padding='7px 0 10px 10px'
				// this.$refs.list_ul.style.display= 'block';
				if (level == 2) {
					this.catalog_list_level2 = this.getcatalog_list_level2(item.id, level);
					this.catalog_list_level3 =[]
				} else if (level == 3) {
					// this.catalog_list_level3=this.getcatalog_list_level2(item.id,level);
					// this.$refs.tow_list_ul.style.display='block'
					for (let s = 0; s < this.$refs.one_list_li.length; s++) {
						// this.$refs.one_list_li[s].children[1].style.display= 'none';
						this.$refs.one_list_li[s].children[0].style.color = '#000';
						this.$refs.one_list_li[s].children[0].style.textDecoration = 'none';
					}
					// this.$refs.one_list_li[index].children[1].style.display= 'block';
					this.$refs.one_list_li[index].children[0].style.color = '#cb5031';
					this.$refs.one_list_li[index].children[0].style.textDecoration = 'underline';



					let dataone = [];
					dataone = this.getcatalog_list_level2(item.id, level);
					for (let i = 0; i < dataone.length; i++) {
						dataone[i].children = this.getcatalog_list_level2(dataone[i].id, dataone[i].level + 1)
					}
					this.catalog_list_level3 = dataone;
					// for(let g=0; g<this.$refs.one_list_li[index].children[1].children.length; g++){
					// 	this.$refs.one_list_li[index].children[1].children[g].children[0].style.color= '#000';
					// 	this.$refs.one_list_li[index].children[1].children[g].children[0].style.textDecoration= 'none';
					// }
					// this.$refs.one_list_li[index].children[1].children[index].children[0].style.color= '#cb5031';
					// this.$refs.one_list_li[index].children[1].children[index].children[0].style.textDecoration= 'underline';
				}
				// for(let i=0; i<this.catalog_list.length; i++){
				// 	if(this.catalog_list[i].level==2){
				// 		// this.catalog_list_level2.push(this.catalog_list[i])

				// 	}else if(this.catalog_list[i].level==3){
				// 		this.catalog_list_level3.push(this.catalog_list[i])
				// 	}else if(this.catalog_list[i].level==4){
				// 		this.catalog_list_level4.push(this.catalog_list[i])
				// 	}else if(this.catalog_list[i].level==5){
				// 		this.catalog_list_level5.push(this.catalog_list[i])
				// 	}
				// }
				this.nav_top = true;

			},
			getcatalog_list_level2(parent_id, level) {
				console.log(parent_id, level)
				let data = [];
				console.log(this.catalog_list)
				for (let h = 0; h < this.catalog_list.length; h++) {
					if (this.catalog_list[h].parent_id == parent_id && this.catalog_list[h].level == level) {
						data.push(this.catalog_list[h])
					}
				}
				data.sort(this.compare('sort'));
				console.log(data)
				return data
			},
			compare: function(property) {
				return function(a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2;
				}
			},
			mouseout_catalog() {
				// this.$refs.list_ul.style.display= 'none';
				// this.$refs.list_ul.style.padding='0'
				this.nav_top = false;
				// this.resource_type=null;
				this.catalog_list = [];
			},
			myproject() {
				let token_nav = this.Account.GetToken();
				if (token_nav == null) {
					this.$router.push('SignIn');
					return
				} else {
					this.$router.push({
						path: "MyShow",
						query: {
							level_0: 3
						}
					});
				}
			},
			modebase(level, parentnodeid, sequence, index, item_level) {
				console.log(level,parentnodeid,sequence,index,item_level)
				let resource_type;
				this.huifucolo();
				this.$refs.navlist[index].style.color = '#cb5031'
				if (item_level == 1 && sequence == 1) {
					resource_type = 1
				} else if (item_level == 1 && sequence == 2) {
					resource_type = 2
				} else if (item_level == 1 && sequence == 3) {
					resource_type = 3
					sequence = 2
				}
				this.$router.push({
					path: '/ModeBase',
					query: {
						level_0: level,
						parentnodeid: parentnodeid,
						sequence: sequence,
						resource_type: resource_type
					}
				})
			},
			upload() {
				
				
				this.huifucolo();
				this.$refs.shangchuan.$el.style.color = '#cb5031'
				
				let token_nav = this.Account.GetToken()
				if (token_nav == null) {
					this.$router.push('SignIn')
				} else {
					let ids = 4;
					this.$router.push({
						path: "/MyShow",
						query: {
							ids: 4,
							level_0: 3
						}
					})
				}
			},
			feedback() {
				this.dialogVisiblse = true
			},
			feedback_determine() {
				this.dialogVisiblse = false
			},
			myproject() {
				this.huifucolo()
				this.$refs.xiuxiu.style.color = '#cb5031'
				let token_nav = this.Account.GetToken();
				if (token_nav == null) {
					this.$router.push('SignIn')
					return
				} else {
					this.$router.push({
						path: "MyShow",
						query: {
							level_0: 3
						}
					});
				}
			},
			toindex() {

				this.huifucolo()
				this.$refs.shoye.style.color = '#cb5031'


				this.$router.push({
					path: "/"
				});
			},
			//修改资料跳转
			amend() {
				this.$router.push("/About");
			},
			//开通VIP
			liberal() {
				this.$router.push("/VipPrivilge");
			},
			//我的钱包
			myqb() {
				this.$router.push("/MyWallet");
			},
			href_shop() {
				this.huifucolo();
				this.$refs.gowuche.$el.style.color = '#cb5031'
				let token_nav = this.Account.GetToken()
				if (token_nav == null) {
					this.$router.push('SignIn')
					// this.$message({
					//     showClose: true,
					//     message: "请先登录！",
					//     type: "warning",
					// });
				} else {
					this.$router.push("/myshoppingcart");
				}
			},
			register() {
				window.location.href = "../../public/register/register.html";
				// window.open('');
			},
			//内存空间扩容
			dilatation() {
				this.$router.push("/MemorySpace");
			},
			//未读消息
			weidu() {
				this.$router.push({
					path: "/MyInform",
					query: {
						open: true
					}
				});
			},
			//密码修改
			mima() {
				this.$router.push("/About");
			},
			mouseleave1() {
				this.show3 = false;
			},
			isLogin1: function() {
				let session = this.Account.GetSession()
				let storage = this.Account.GetStorage();
				if (session != null) {
					if(session.type_role==1){
						session.type_role='学生'
					}else if(session.type_role==2){
						session.type_role='老师'
					}else{
						session.type_role='员工'
					}
					this.session = session;
					this.vipsession = storage;
					this.unread_message = storage.unread_message;

					this.isLogin = 1;
				} else {
					this.isLogin = 0;
				}
				console.log(this.isLogin)
			},
			seover: function() {
				let _this = this;
				this.show3 = !this.show3;
				requestStorage().then(res => {
					localStorage.setItem("storage", JSON.stringify(res.ret));
				})
				let storage_nav = this.Account.GetStorage();
				this.vipsession = storage_nav;
				this.unread_message = storage.unread_message;
			},
			loginevent() {
				// this.showlogin = !this.showlogin;.
				this.$router.push('SignIn')
			},
			closeDialog(data) {
				// this.showlogin = data;
			},
			signout() {
				// this.$refs.logins.$el.style.marginTop='40px';
				let data_this = this;
				this.show3 = false;
				let token = this.Account.GetToken();
				let obj = Object();
				obj.token = token;
				requestSignout(obj).then((res) => {
					if (res.code != 0) {
						this.$message.error(res.ret);
					} else {
						this.Account.LogOut();
						this.isLogin1();
						data_this.$message({
							message: "退出成功",
							type: "success",
						});
						this.$router.push({
							path: "/"
						});

					}
				});
			},
			//初始化搜索框数据
			init_search() {
				let data = [{
						value: 'null',
						label: "全部",
					}, {
						value: "1",
						label: "模型库",
					},
					{
						value: "2",
						label: "动画库",
					},
					{
						value: "3",
						label: "脚本库",
					},
				];
				this.$refs.ComTopSearch.init(data, this.search_callback);
			},
			//搜索按钮点击回调函数
			search_callback(data, select_index) {
				console.log(data);
				console.log(select_index);
			},
			getScroll(e) {
				var top = e.srcElement.scrollingElement.scrollTop;
				this.top = top;
				if (top > 99) {
					this.$refs.head.style.backgroundColor = 'white'
				} else {
					this.$refs.head.style.backgroundColor = 'hsla(0,0%,100%,.1)'


				}
			},
			huifucolo() {
				this.$refs.gowuche.$el.style.color = '#000'
				this.$refs.shangchuan.$el.style.color = '#000'
				this.$refs.yulan.$el.style.color = '#000'
				this.$refs.kefu.style.color = '#000'
				this.$refs.shoye.style.color = '#000'
				this.$refs.xiuxiu.style.color = '#000'
				for (let h = 0; h < this.$refs.navlist.length; h++) {
					this.$refs.navlist[h].style.color = '#000'
				}
			}
		},
		mounted() {
			this.init_search();
			this.isLogin1();
			this.getaccount_id();
			requestCatalog(0).then(res => {
				for (let i = 0; i < res.ret.length; i++) {
					if (res.ret[i].id == 14126) {
						res.ret[i].id = 10005;
					}
				}
				this.navBar = res.ret;
				console.log(this.navBar)
			})



			if (this.MyShow.GetHomeCatalog() == null) {
				catalog_list().then(rese => {
					console.log(rese)
					let data = JSON.parse(rese.ret)
					this.MyShow.SetHomeCatalog(data);
					this.catalog_list = rese;
					this.tihuan()
				})
			} else {
				this.catalog_list = this.MyShow.GetHomeCatalog();
				this.tihuan()
			}

			let href = location;
			if (this.Account.GetStorage().vip_type == 1) {
				this.vip_icon_show = true;
			}

		},
		destroyed() {
			window.removeEventListener('scroll', this.getScroll);
		},
		watch: {
			$route: function() {
				this.$refs.leftdata.style.display='none'
				if (location.hash == '#/') {
					window.addEventListener('scroll', this.getScroll);
					this.$refs.head.style.position = 'fixed'
					this.$refs.head.style.backgroundColor = 'hsla(0,0%,100%,.1)'
				} else {
					window.removeEventListener('scroll', this.getScroll);
					this.$refs.head.style.position = 'inherit'
					this.$refs.head.style.backgroundColor = 'white'
				}
			}
		}
	};
</script>

<style scoped>
	@import "../style/common.css";

	.transition-box {
		margin-bottom: 10px;
		width: 200px;
		height: 100px;
		border-radius: 4px;
		background-color: #409eff;
		text-align: center;
		color: #fff;
		padding: 40px 20px;
		box-sizing: border-box;
		margin-right: 20px;
	}

	a {
		text-decoration: none;
	}

	.login {
		height: 64px;
		line-height: 64px;
	}

	.login:hover {
		background: rgba(255, 146, 98, 0.9) !important;
	}

	.login span {
		font-size: 14px;
		color: #999;
		cursor: pointer;
	}

	.head {
		/* position: inherit; */
		width: 100%;
		height: 64px;
		background: white;
		border-bottom: 1px solid #dfdfdf;
		z-index: 100;
		/* transition: 1s; */
		transition: 0.3s;
	}

	.head:hover {
		background-color: white !important;
	}

	/* .header-right{
  width: 30px;
  z-index: 999999999999999;
} */
	.header-right .imgdiv {
		font-size: 12px;
		width: 30px;
		height: 30px;
		margin-top: 35px;
		cursor: pointer;
		transition: 1s;
		/* margin-right: 500px; */
	}

	/* 小窗体信息 */
	.img-message {
		width: 420px;
		height: 340px;
		background-color: #ffffff;
		transform: translate(-314px, 0px);
		padding: 1px;
		transition: 0.5s;
		font-size: 14px;
		z-index: 80000;
		position: absolute;
	}

	.dredge-vip1,
	.dredge-vip2,
	.internal-storage1,
	.internal-storage2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 6px;
		cursor: pointer;

	}

	.dredge-vip1:hover,
	.dredge-vip2:hover,
	.internal-storage1:hover,
	.internal-storage2:hover {
		background: #ccc;
	}



	.dredge-vip1,
	.dredge-vip2,
	.internal-storage1,
	.internal-storage2 {
		height: 42px;
		/* background-color: red; */
	}

	.dredge-vip1,
	.dredge-vip2,
	.internal-storage1 span b {
		color: #007bff;
	}

	.dredge-vip1 span b,
	.dredge-vip2 span b,
	.internal-storage1 span b {
		cursor: pointer;
	}


	.dredge-vip-cc {
		color: black;
	}

	.dredge-vip-cc a {
		font-size: 12px;
		color: #999;
	}

	.modifieddata {
		height: 124px;
		background-color: #c95033;
		font-size: 14px;
		color: #fff;
		display: flex;
	}

	.modifieddata-one {
		width: 124px;
		height: 124px;
	}

	.modifieddata span {
		display: inline-block;
		margin-top: 18px;
		color: white;
		font-size: 16px;
	}

	.modifieddata span b {
		margin-left: 9px;
		cursor: pointer;
	}

	.modifieddata-one img {
		height: 100%;
		width: 100%;
		/* display: block; */
	}

	.dredge-vip {
		width: 420px;
	}

	.internal-storage {
		width: 420px;
		height: 43px;
	}

	.img-message-bottom {
		border-radius: 4px;
		height: 51px;
		line-height: 30px;
		background-color: #f5f5f5;
	}

	.img-message-bottom span {
		font-size: 14px;
		color: #c95031;
		margin-left: 40px;
		height: 51px;
		line-height: 51px;
		cursor: pointer;
	}

	.iframe {
		width: 100%;
		height: 100%;
		position: relative;
		left: 0;
		top: 0;
	}



	.zhifutow {
		height: 100px;
		line-height: 100px
	}

	.hoverback {
		cursor: pointer;
		padding-top: 5px;
		padding-bottom: 5px;

	}

	.hoverback:hover {
		background: #285E8E;
	}

	.ShoppingCart {
		display: block;
		width: 35px;
		height: 35px;
		background: url(../assets/img/购物车空.png)no-repeat center center;
		background-size: 28px;
		margin: 0px auto;
		display: block;
	}

	.ShoppingCart:hover {
		background: url(../assets/img/购物车空 hover.png)no-repeat center center;
		background-size: 28px;
	}

	.gowuc {
		height: 64px;
		line-height: 64px;
		text-align: center;
	}

	.header_title {
		width: 190px;
		height: 124px;
		margin-left: 22px;
	}

	.header_title p {
		text-align: left;
		font-size: 12px;
		color: #ebae9b;
		margin-bottom: 22px;
	}

	.GoldCoin {
		display: block;
		width: 420px;
		height: 75px;
		display: flex;
		line-height: 75px;
		justify-content: space-around;
	}

	.GoldCoin p {
		color: #686868;
		font-size: 16px;
	}

	.GoldCoin p span {
		color: #cb5031;
		font-size: 24px;
	}

	.gowuc:hover>span {
		color: #c63d1b !important;
		text-decoration: underline;
	}

	.PopUp {
		display: flex;
		position: absolute;
		/* padding-left: 300px; */
		left: 0px;
		top: 64px;
		/* z-index: 9999999999; */
		box-sizing: border-box;
		width: 100%;
		background-color: white;
		height: 400px;
	}

	/* .one_list_li:hover>p{
		color: #cb5031;
		text-decoration: underline;
		background-color: #f3f3f3;
	} */
	/* .hovertext>p{
		color: #cb5031;
		text-decoration: underline;
		cursor: pointer;
	} */
	.hovertext:hover>p {
		color: #cb5031;

		cursor: pointer;
	}

	.san_list_li {
		width: 100%;
		cursor: pointer;
		line-height: 30px;
		text-indent: 10px;
		display: flex;
	}

	.si_list_li {
		height: 30px;
		line-height: 30px;
	}

	.text_over {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	>>>.el-input__inner:focus {
		border-color: rgb(203, 80, 49);
	}

	>>>.el-select .el-input.is-focus .el-input__inner {
		border-color: rgb(203, 80, 49);
	}
	.QQ{
		display: none;
		width: 200px;
		height: 300px;
		background: #0055AA;
		position: relative;
		top: 0;
		left: 0;
	}
	>>>.el-link.is-underline:hover:after{
		display: none;
	}
</style>
